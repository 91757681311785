import { merge } from 'lodash-es';
import * as Yup from 'yup';
import { AchievementCheckOperatorType, IAchievementCheck } from '../../../../dorian-shared/types/achievement/Achievement';
import { IAchievementModal } from '../AchievementsModalTypes';
import { convertYupErrorsToFormik } from './convertYupErrorsToFormik';

const achievementSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(254, 'Must be 254 characters or less')
    .required('Required')
    .matches(/^[a-zA-Z0-9_\-.$&]*$/, 'Only letters, numbers, and special characters are allowed'),
  displayName: Yup.string()
    .max(25, 'Must be 25 characters or less'),
  description: Yup.string()
    .max(254, 'Must be 254 characters or less'),
  icon: Yup.string()
    .when('check', {
      is: (check: IAchievementCheck) => check.operator === AchievementCheckOperatorType.AtLeast,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  check: Yup.object().shape({
    variableId: Yup.number()
      .when('operator', {
        is: AchievementCheckOperatorType.AtLeast,
        then: Yup.number().min(1, 'Required').required('Required'),
        otherwise: Yup.number().nullable().notRequired(),
      }),
    operator: Yup.string()
      .required('Required'),
    value: Yup.mixed()
      .when('operator', {
        is: AchievementCheckOperatorType.AtLeast,
        then: Yup.number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
          .required('Required')
          .max(999999999, 'Must be 999999999 or less'),
        otherwise: Yup.string().required('Required'),
      }),
  }),
  reward: Yup.object()
    .when('check', {
      is: (check: IAchievementCheck) => check.operator === AchievementCheckOperatorType.HasCompleted,
      then: Yup.object().shape({
        contentId: Yup.string()
          .required('Required'),
        type: Yup.number()
          .required('Required'),
        delaySec: Yup.number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
          .required('Required')
          .min(0, 'Must be 0 or more'),
      }),
      otherwise: Yup.object().shape({
        contentId: Yup.string()
          .nullable()
          .notRequired(),
        type: Yup.number()
          .nullable()
          .notRequired(),
        delaySec: Yup.number()
          .nullable()
          .notRequired(),
      }),
    }),
});

export function validateGeneral(values: IAchievementModal[], i: number, errors: { [p: string]: Record<string, any> }) {
  const errorsFound = { ...errors };
  try {
    achievementSchema.validateSync(values[i], { abortEarly: false });
  } catch (validationErrors) {
    if (validationErrors instanceof Yup.ValidationError) {
      validationErrors.inner.forEach((error) => {
        if (error.path) {
          errorsFound[i] = merge(errorsFound[i] || {}, convertYupErrorsToFormik(error));
        }
      });
      console.log('errors', i, errorsFound, values);
    } else {
      console.error(validationErrors);
    }
  }
  return errorsFound;
}

export const validateUniqueNames = (values: IAchievementModal[], i: number, errors: { [key: string]: Record<string, any> }) => {
  const errorsFound = { ...errors };
  const isNameExist = values.some((v, j) => v.name === values[i].name && j !== i);
  if (isNameExist) {
    errorsFound[i] = {
      ...errorsFound[i],
      name: 'Name should be unique',
    };
  }
  return errorsFound;
};
