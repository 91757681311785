import { Story } from '../../../../../dorian-shared/types/story/Story';
import { MemoryDTO } from '../../../Book/MemoryBank/memoryBankTypes';
import { IAchievementModal } from '../../AchievementsModalTypes';
import { getEpisodeTitleIcons } from '../../utils';
import { AvailableWhenGroupedOptions, AvailableWhenOptions } from './AchievementWhenField';

export const getGroupedOptions = (
  memories: MemoryDTO[],
  values: IAchievementModal[],
  rowIndex: number,
  episodes: Story[],
) => {
  const availableMemories = memories
    .filter((memory) => memory.type === 'number'
    && !values.some((v, i) => Number(v.check.variableId) === Number(memory.id) && i !== rowIndex));

  const availableEpisodes = episodes.filter((episode) => !values.some((v, i) => v.reward?.contentId === episode.uuid && i !== rowIndex));

  const availableWhenGroupedOptions: AvailableWhenGroupedOptions[] = [
    {
      label: 'Memory',
      options: availableMemories.map<AvailableWhenOptions>((memory) => ({
        label: memory.name,
        value: String(memory.id),
        type: 'memory',
      })),
    },
    {
      label: 'Episode',
      options: availableEpisodes.map((episode) => ({
        label: episode.title + getEpisodeTitleIcons(episode),
        value: episode.uuid,
        type: 'episode',
      })),
    },
  ];

  return availableWhenGroupedOptions;
};
