import { useFormikContext } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import Select, { ActionMeta, SingleValue } from 'react-select';
import {
  AchievementCheckOperatorType,
  AchievementRewardType,
} from '../../../../../dorian-shared/types/achievement/Achievement';
import { IAchievementModal } from '../../AchievementsModalTypes';
import classes from '../AchievementField.module.scss';
import { customSelectStyles } from './customSelectStyles';
import { NoOptionsMessage } from './NoOptionMessage';
import { useAchievementWhen } from './useAchievementWhen';

export type AvailableWhenOptions = {
  label: string;
  value: string;
  type: 'memory' | 'episode';
}

export type AvailableWhenGroupedOptions = {
  label: string;
  options: AvailableWhenOptions[];
}

export type AchievementWhenFieldProps = {
  rowIndex: number;
  className?: string;
  onChange?: (value: AvailableWhenOptions, actionMeta: ActionMeta<AvailableWhenOptions>) => void;
}

export function AchievementWhenField(props: AchievementWhenFieldProps) {
  const {
    rowIndex,
    className = 'position-relative',
    onChange,
  } = props;

  const { values, setValues } = useFormikContext<IAchievementModal[]>();

  const {
    value, availableWhenGroupedOptions,
  } = useAchievementWhen(rowIndex);

  const handleWhenFieldChange = (selectedOption: SingleValue<AvailableWhenOptions>, actionMeta: ActionMeta<AvailableWhenOptions>) => {
    const newValues = [...values];
    const currentValue = { ...values[rowIndex] };

    if (selectedOption?.type === 'memory') {
      const newVariableId = Number.isNaN(selectedOption?.value)
        ? 0
        : Number(selectedOption.value);
      newValues[rowIndex].check.operator = AchievementCheckOperatorType.AtLeast;
      newValues[rowIndex].check.variableId = newVariableId;
    }

    if (selectedOption?.type === 'episode') {
      newValues[rowIndex].check.value = selectedOption?.value ? selectedOption?.value : '';
      newValues[rowIndex].check.operator = AchievementCheckOperatorType.HasCompleted;

      const willSetRewardType = currentValue.reward?.type || currentValue.reward?.type === AchievementRewardType.None;
      if (willSetRewardType) {
        newValues[rowIndex].reward!.type = AchievementRewardType.Episode;
      }

      const willSetRewardDelaySec = currentValue.reward?.delaySec === undefined || currentValue.reward.delaySec === null;
      if (willSetRewardDelaySec) {
        newValues[rowIndex].reward!.delaySec = 0;
      }
    }
    setValues([...newValues]);
    onChange?.(selectedOption as AvailableWhenOptions, actionMeta);
  };

  const errorMessage = value
    ? undefined
    : 'Required';

  return (
    <Form.Group className={className}>
      <Form.Label
        htmlFor="when"
        className={classes.label}
      >
        When
      </Form.Label>
      <Select<AvailableWhenOptions>
        className="react-select-container"
        classNamePrefix="react-select"
        menuShouldBlockScroll
        tabSelectsValue
        placeholder="[Select]"
        options={availableWhenGroupedOptions}
        value={value}
        onChange={handleWhenFieldChange}
        menuPlacement="auto"
        components={{
          NoOptionsMessage,
        }}
        styles={customSelectStyles}
      />
      {errorMessage && (
        <div className="d-block invalid-tooltip">
          {errorMessage}
        </div>
      )}
    </Form.Group>
  );
}
