import async from 'async';
import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, FormControl, InputGroup, Modal, Row,
} from 'react-bootstrap';
import ImgArr from '../../../assets/images/arr-002.svg';
import { api } from '../../api';
import { Auth } from '../../Auth/Auth';
import {
  BonusFormGroup,
} from '../StoryBranches/Options/BonusFormGroup/RequiredAchievementFormGroup';
import { LockedFormGroup } from '../StoryBranches/Options/LockedFormGroup/LockedFormGroup';
import { getUserName } from '../Users';
import { getAddStoryUserAccess } from './AddStoriesUtils';

const auth = new Auth();

export class AddStories extends Component {
  constructor(...args) {
    super(...args);
    const { data } = this.props;

    this.state = {
      validated: false,
      formError: null,
      pageTitle: data !== null ? `Edit Episode: "${data.title}"` : 'Add New Episode',
      title: data !== null ? data.title : '',
      revision: data !== null ? data.revision : 1,
      summary: data !== null ? data.summary : '',
      chapter: data?.chapter != null ? Number(data.chapter) : 1,
      /* eslint-disable react/no-unused-state */
      author: data !== null ? data.author : '',
      genre: [],
      storyTags: [],
      tags: [],
      /* eslint-enable react/no-unused-state */
      wizardStep: null,
      episodeErrorLimit: null,
      episodeSummaryErrorLimit: null,
      isBonus: data?.isBonus ?? false,
      isLocked: data?.isLocked ?? false,
    };
    this.user = auth.getUser();
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  componentDidMount() {
    this.loadData();
    const wizardStep = JSON.parse(localStorage.getItem('wizardStep'));
    if (wizardStep === 5) {
      this.setState({
        wizardStep,
        title: `${getUserName(this.user)} First Episode`,
        // eslint-disable-next-line react/no-unused-state
        author: getUserName(this.user),
        summary: `${getUserName(this.user)} First Episode Description`,
      }, () => {
        localStorage.setItem('wizardStep', wizardStep);
      });
    }
  }

  loadData() {
    async.parallel({
      genres: (callback) => {
        api.get('/v1/genres')
          .then((res) => {
            callback(null, res.data.genres);
          }).catch((error) => {
            callback(error, null);
          });
      },
      tags: (callback) => {
        api.get('/v1/tags')
          .then((res) => {
            callback(null, res.data.tags);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      if (err) {
        this.errorAlert(err);
      } else {
        const { data } = this.props;

        this.setState({
          /* eslint-disable react/no-unused-state */
          genre: res.genres,
          storyTags: res.tags,
          tags: data ? data.tags : [],
          /* eslint-enable react/no-unused-state */
        });
      }
    });
  }

  addStories(value, validated) {
    const {
      bookID, currentFolder, update, data,
    } = this.props;
    const { wizardStep } = this.state;

    if (currentFolder) {
      // eslint-disable-next-line no-param-reassign
      value.folderId = currentFolder;
    }
    const link = bookID ? `/v1/books/${bookID}/chapters` : '/v1/stories';
    if (validated === true) {
      if (wizardStep === 5) {
        localStorage.setItem('wizardStep', wizardStep + 1);
      }
      if (data !== null) {
        api.put(`${link}/${data.id}`, value)
          .then(() => {
            this.setState({
              // eslint-disable-next-line react/no-unused-state
              addStoriesActive: false,
            });
            update();
          })
          .catch((error) => {
            if (error.response) {
              this.errorAlert(error.response.data.error);
            }
          });
      } else {
        api.post(link, value)
          .then((res) => {
            this.setState({
              // eslint-disable-next-line react/no-unused-state
              addStoriesActive: false,
            });
            update();
            if (!bookID) {
              const newStoriesSteps = {
                id: res.data.story.id,
                step: 1,
              };
              sessionStorage.setItem('newStories', JSON.stringify(newStoriesSteps));
              window.location.assign(`/stories/${res.data.story.id}/branches`);
            }
          })
          .catch((error) => {
            if (error.response) {
              this.errorAlert(error.response.data.error);
            }
          });
      }
    }
  }

  handleSubmit(event) {
    const { limits } = this.props;
    const { summary } = this.state;

    event.preventDefault();
    const form = event.currentTarget;
    let validated = form.checkValidity();
    const storyToSave = serialize(form, { hash: true, empty: true });

    if (storyToSave.title && storyToSave.title.length > Number(limits.story_title_max.value)) {
      validated = false;
      this.setState({ validated: false, episodeErrorLimit: true });
      event.stopPropagation();
    }

    if (storyToSave.summary && storyToSave.summary.length > Number(limits.story_summary_max.value)) {
      validated = false;
      this.setState({ validated: false, episodeSummaryErrorLimit: true });
      event.stopPropagation();
    }

    const isBonus = storyToSave.isBonus === 'on';
    storyToSave.isBonus = isBonus;
    storyToSave.chapter = isBonus ? null : parseInt(storyToSave.chapter, 10);

    storyToSave.isLocked = storyToSave.isLocked === 'on';

    if (summary.match(/^\s*$/)) {
      validated = false;
      this.setState({
        validated: false,
        summary: '',
      });
    }

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addStories(storyToSave, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  render() {
    const {
      update,
      currentFolder,
      bookID,
      limits,
      ...otherProps
    } = this.props;
    const {
      validated,
      chapter,
      wizardStep,
      summary,
      episodeSummaryErrorLimit,
      pageTitle,
      title,
      formError,
      episodeErrorLimit,
      revision,
      isBonus,
      isLocked,
    } = this.state;

    const {
      story_title_max: storyTitleMax,
      story_summary_max: storySummaryMax,
    } = limits;
    const {
      onHide, user, disabled, data,
    } = this.props;

    const {
      isAdmin,
      isExistingEpisodeEdit,
      isUserHasMemoryBankAccess,
      isBonusFieldTitle,
    } = getAddStoryUserAccess(user?.role, Boolean(data));

    const isLockedVisible = chapter > 1;

    return (
      <Modal
        {...otherProps}
        limits={limits}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >

          <Modal.Header closeButton>
            <Modal.Title>{pageTitle}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            <Form.Row>
              <Form.Group
                as={Col}
                controlId="StoriesTitle"
                className={`${wizardStep === 5 ? 'wizardActive' : ''}`}
              >
                <Form.Label>Episode Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Episode Title"
                  pattern="^([A-Za-z]|[0-9]|_|-| |[.!?,:;()&|[\]\/@]|'|\u0022)+$"
                  name="title"
                  defaultValue={title}
                  className={episodeErrorLimit ? 'text-limit' : null}
                  onChange={(e) => {
                    const { value } = e.target;

                    this.setState({
                      episodeErrorLimit: value && value.length > Number(storyTitleMax.value),
                      title: value,
                    });
                  }}
                />
                <Form.Text className="char-limit-info-box">
                  {Number(storyTitleMax.value) - (title ? title.length : 0)}
                </Form.Text>

                <Form.Control.Feedback
                  type="invalid"
                  className={episodeErrorLimit ? 'd-block' : 'd-none'}
                >
                  Episode Title is too long.
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                  Please choose a title.
                </Form.Control.Feedback>
                {wizardStep === 5
                  ? (
                    <div className="sidebarArr">
                      <img src={ImgArr} alt="" />
                      <span>Please input the Episode Title</span>
                    </div>
                  )
                  : null}
              </Form.Group>
            </Form.Row>
            <Form.Row>

              <Form.Group
                as={Col}
                controlId="StorySummary"
                className={`${wizardStep === 5 ? 'wizardActive' : ''}`}
              >
                <Form.Label>Episode Description</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows="1"
                  maxLength="280"
                  placeholder="Episode Description"
                  name="summary"
                  isInvalid={validated && summary.match(/^\s*$/)}
                  value={summary}
                  className={episodeSummaryErrorLimit ? 'text-limit' : null}
                  onChange={(e) => {
                    const { value } = e.target;
                    const isTooLong = value && value.length > Number(storySummaryMax.value);

                    this.setState({
                      episodeSummaryErrorLimit: isTooLong,
                      summary: value,
                    });
                  }}
                />
                <Form.Text className="char-limit-info-box">
                  {Number(storySummaryMax.value) - (summary ? summary.length : 0)}
                </Form.Text>
                <Form.Control.Feedback
                  type="invalid"
                  className={episodeSummaryErrorLimit ? 'd-block' : 'd-none'}
                >
                  Episode Description is too long.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a description.
                </Form.Control.Feedback>
                {(wizardStep === 5) && (
                <div className="sidebarArr">
                  <img src={ImgArr} alt="" />
                  <span>Please input the Episode Description</span>
                </div>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Col sm="auto">
                <BonusFormGroup
                  value={Boolean(isBonus)}
                  onChange={(value) => this.setState({ isBonus: value })}
                  title={isBonusFieldTitle}
                disabled={(!isAdmin && isExistingEpisodeEdit) || !isUserHasMemoryBankAccess}
                />
              </Col>
              {isLockedVisible && (
              <Col sm="auto">
                <LockedFormGroup
                  value={Boolean(isLocked)}
                  onChange={(value) => this.setState({ isLocked: value })}
                  disabled={!isAdmin && isExistingEpisodeEdit}
                />
              </Col>
              )}
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4} controlId="StoryChapter">
                <Form.Label>Episode #</Form.Label>

                {isBonus
                  ? (
                    <div>
                      This is a bonus episode.
                    </div>
                  )
                  : (
                    <InputGroup>
                      <FormControl
                        required
                        type="text"
                        placeholder="Episode #"
                        name="chapter"
                        value={chapter}
                        pattern="^[0-9]+$"
                        min={1}
                        onChange={(e) => {
                          this.setState({
                            chapter: e.target.value.replace(/[^0-9]/g, ''),
                          });
                        }}
                      />
                      <InputGroup.Append>
                        <Button
                          className="numInpBtn"
                          variant="secondary"
                          onClick={() => {
                            this.setState({
                              chapter: chapter <= 1 ? 1 : Number(chapter) - 1,
                            });
                          }}
                        >
                          &#9660;
                        </Button>
                        <Button
                          className="numInpBtn"
                          variant="secondary"
                          onClick={() => {
                            this.setState({
                              chapter: Number(chapter) + 1,
                            });
                          }}
                        >
                          &#9650;
                        </Button>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        Please choose an episode number.
                      </Form.Control.Feedback>
                    </InputGroup>
                  )}
              </Form.Group>

              <Form.Group as={Col} md={1} controlId="StoryVersion">
                <Form.Label>Version</Form.Label>

                <InputGroup>
                  <FormControl
                    type="text"
                    placeholder="Version"
                    disabled
                    name="version"
                    value={revision}
                  />
                </InputGroup>
              </Form.Group>
            </Form.Row>

            {(isAdmin && isExistingEpisodeEdit) && (
            <Form.Row>
              <Form.Group as={Col} controlId="StoryUUID">
                <Form.Label>Episode UUID</Form.Label>

                <FormControl
                  type="text"
                  placeholder="Episode UUID"
                  disabled
                  value={data?.uuid ?? ''}
                />

              </Form.Group>
            </Form.Row>
            )}

          </Modal.Body>

          <Modal.Footer
            className="justify-content-between py-0"
          >
            <Row
              style={{ width: '103%' }}
            >
              <Col sm={6} className="py-3">
                {(isAdmin && isExistingEpisodeEdit) && (
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    onHide(data);
                  }}
                  disabled={(!data)}
                >
                  Overwrite Episode
                </Button>
                )}
              </Col>
              <Col sm={6} className="py-3 text-right">
                <Button
                  type="reset"
                  variant="secondary"
                  onClick={() => onHide()}
                  className="mx-2"
                  disabled={(wizardStep === 5)}
                >
                  Cancel
                </Button>

                <span className={`wizardBtnBox ${wizardStep === 5 ? 'wizardActive' : ''}`}>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={episodeErrorLimit || episodeSummaryErrorLimit || disabled}
                  >
                    Save
                  </Button>
                  {(wizardStep === 5) && (
                  <div className="sidebarArr">
                    <img src={ImgArr} alt="" />
                    <span>click to continue</span>
                  </div>
                  )}
                </span>
              </Col>
            </Row>

          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
