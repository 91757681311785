import { ValidationError } from 'yup';

type FormikErrors<T> = {
  [K in keyof T]?: string | FormikErrors<T[K]>;
};

/**
 * Utility function to set nested values in an object.
 *
 * @param obj - The target object.
 * @param path - The dot-separated path to the property.
 * @param value - The value to set at the path.
 */
function set<T>(obj: T, path: string, value: any): void {
  const keys = path.split('.');
  let current: any = obj;

  keys.forEach((key, index) => {
    if (!current[key]) {
      current[key] = index === keys.length - 1 ? value : {};
    }
    current = current[key];
  });
}

/**
 * Converts Yup ValidationError to a Formik-compatible errors object.
 *
 * @param error - The Yup ValidationError object.
 * @returns The Formik-compatible errors object.
 */
export function convertYupErrorsToFormik<T>(error: ValidationError): FormikErrors<T> {
  const errors: FormikErrors<T> = {};

  if (error.inner && error.inner.length > 0) {
    error.inner.forEach((err) => {
      if (err.path) {
        set(errors, err.path, err.message);
      }
    });
  } else if (error.path) {
    set(errors, error.path, error.message);
  }

  return errors;
}
