import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useClientVariables } from 'hooks/useClientVariables';
import ImgArr from '../../../assets/images/arr-002.svg';
import ImgHelpIcon from '../../../assets/images/ui/ic-contact-help.svg';
import { CreateNodeButton } from '../../pages/StoryBranches/CreateNodeButton';
// eslint-disable-next-line import/no-cycle
import { Options } from '../../pages/StoryBranches/Options';
import { Zoom } from '../../pages/StoryBranches/Zoom';

const environmentName = process.env.REACT_APP_ENVIRONMENTS_NAME || null;

export function Sidebar(props) {
  const { update, sidebar, wizard } = props;
  const browserHistory = useHistory();
  const [contactFormUrl] = useClientVariables('contact_form_url');

  return (
    <div className="sidebar" data-testid="sidebar">
      <h5 className="text-center border-bottom pb-3 mb-5 site-logo">
        <Link to="/home">
          Dorian
        </Link>
        {environmentName && <i>{environmentName}</i>}
      </h5>
      <div className="sidebar-content">
        {sidebar.nav.map((object, i) => {
          if (object.NotAdminPermissions) {
            return null;
          }

          if (!object.variant && !object.title) {
            return null;
          }

          if (object.variant === 'line') {
            // eslint-disable-next-line react/no-array-index-key
            return (<div key={i} className="border-bottom pb-4 mb-4" />);
          }

          if (object.variant === 'space') {
            // eslint-disable-next-line react/no-array-index-key
            return (<div key={i} className="pb-4" />);
          }

          if (object.variant === 'snapToGridCheckbox') {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="pt-5 pb-3"
              >
                <Form.Group controlId={object.variant}>
                  <Form.Check
                    custom
                    id={object.id}
                    disabled={object.disabled}
                    defaultValue={object.value}
                    checked={object.value}
                    onChange={object.action}
                    type="checkbox"
                    label={object.title}
                  />
                </Form.Group>
              </div>
            );
          }

          if (object.variant === 'showGridCheckBox') {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className="pt-0 pb-3"
              >
                <Form.Group controlId={object.variant}>
                  <Form.Check
                    custom
                    id={object.id}
                    disabled={object.disabled}
                    defaultValue={object.value}
                    checked={object.value}
                    onChange={object.action}
                    type="checkbox"
                    label={object.title}
                  />
                </Form.Group>
              </div>
            );
          }

          if (object.variant === 'zoom') {
            return (
              <Zoom
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                branchesScale={object.branchesScale}
                update={object.action}
              />
            );
          }

          if (object.variant === 'Options') {
            return (
              <Options
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                ref={object.ref}
                id={object.id}
                user={object.user}
                update={object.update}
                limits={object.limits}
                modeEdit={object.modeEdit}
                disabled={wizard ? wizard !== object.activeWizardStep : false}
                story={object.story}
              />
            );
          }

          if (object.variant === 'CreateNodeButton') {
            return (
              <CreateNodeButton
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                title={object.title}
                branchesScale={object.branchesScale}
                addBranchActive={object.addBranchActive}
                modeEdit={object.modeEdit}
                action={object.action}
              />
            );
          }

          if (object.action) {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                className={`my-2 sidebarItem ${wizard && wizard === object.activeWizardStep ? 'wizardActive' : ''}`}
              >
                <Button
                  block
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  variant={object.variant}
                  disabled={wizard ? wizard !== object.activeWizardStep : object.disabled}
                  onClick={() => {
                    if (wizard > 0) {
                      update(wizard + 1);
                    }
                    object.action();
                  }}
                >
                  {object.loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  {object.title}
                </Button>
                {wizard && wizard === object.activeWizardStep ? (
                  <div className="sidebarArr">
                    <img src={ImgArr} alt="" />
                    <span>click to continue</span>
                  </div>
                ) : null}
              </div>
            );
          }

          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={`my-2 sidebarItem ${wizard && wizard === object.activeWizardStep ? 'wizardActive' : ''}`}
            >
              <Button
                block
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                variant={object.variant}
                disabled={wizard ? wizard !== object.activeWizardStep : object.disabled}
                onClick={() => browserHistory.push(object.href)}
              >
                {object.title}
              </Button>
            </div>
          );
        })}
      </div>
      <div className="sidebarVersion">
        <a href={contactFormUrl} target="_blank" rel="noreferrer">
          <img src={ImgHelpIcon} alt="help icon" />
        </a>
        Version:
        {' '}
        {global.appVersion}
      </div>
    </div>
  );
}
