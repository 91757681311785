import React from 'react';
import { Col, FormCheck, FormGroup } from 'react-bootstrap';
import cs from './LockedFormGroup.module.scss';

type LockedFormGroupProps = {
  value: boolean,
  onChange: (value: boolean) => void
  disabled?: boolean
}

export function LockedFormGroup(props: LockedFormGroupProps) {
  const { value, onChange, disabled } = props;

  return (
    <FormGroup as={Col} controlId="isLocked" className={cs.formGroup}>
      <FormCheck
        custom
        name="isLocked"
        disabled={disabled}
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        label="Locked"
      />

    </FormGroup>
  );
}
