import React from 'react';
import { components, NoticeProps } from 'react-select';
import { AvailableWhenOptions } from './AchievementWhenField';

export function NoOptionsMessage(props: NoticeProps<AvailableWhenOptions>) {
  return (
    <components.NoOptionsMessage {...props}>
      <div style={{ color: '#999', padding: '10px', textAlign: 'center' }}>
        None available
      </div>
    </components.NoOptionsMessage>
  );
}
