import async from 'async';
import { Formik } from 'formik';
import { isEqual } from 'lodash-es';
import React, { PropsWithChildren } from 'react';
import { useAchievementsContext } from '../../../../contexts/AchievementsContext/AchievementsContext';
import { IAchievementGetDTO } from '../../../../dorian-shared/types/achievement/Achievement';
import { logger } from '../../../../services/loggerService/loggerService';
import { showToast } from '../../../ui/utils';
import { IAchievementModal } from '../AchievementsModalTypes';
import { convertAchievementToPostDTO } from '../utils';
import {
  validateGeneral, validateUniqueNames,
} from './achievementValidations';

export interface AchievementsFormikWrapperProps extends PropsWithChildren {
  initialValues: IAchievementModal[];
  onSubmit: (willHide?: boolean) => void;
}

export function AchievementsFormikWrapper(props: AchievementsFormikWrapperProps) {
  const { children, onSubmit, initialValues } = props;

  const {
    achievements, createAchievement, updateAchievement, deleteAchievement, updateOrder,
    setIsLoading, isSendNotifications,
  } = useAchievementsContext();

  const handleSubmit = async (values: IAchievementModal[]) => {
    const createPromises: (() => Promise<IAchievementGetDTO | null>)[] = [];
    const updatePromises: (() => Promise<IAchievementGetDTO | null>)[] = [];
    const deletePromises: (() => Promise<void>)[] = [];

    achievements.forEach((achievement) => {
      const isExist = values.find((value) => value.id === achievement.id);
      if (!isExist) {
        deletePromises.push(() => deleteAchievement(achievement.id));
      }
    });

    values.forEach((value) => {
      const achievementDTO = achievements.find((a) => a.id === value.id);

      const valueToCompare = convertAchievementToPostDTO(value);
      const achievementToCompare = achievementDTO ? convertAchievementToPostDTO(achievementDTO) : null;

      if (value.isAdded || !achievementDTO) {
        createPromises.push(async () => await createAchievement(valueToCompare));
      } else if (!isEqual(achievementToCompare, valueToCompare)) {
        updatePromises.push(async () => await updateAchievement(achievementDTO.id, valueToCompare));
      }
    });

    setIsLoading(true);
    const LIMIT = 1;
    async.series({
      delete: (callback: { (error: unknown, result: unknown): void }) => {
        const deleteLimitRequests = deletePromises.map((promise) => async () => await promise());
        async.parallelLimit(deleteLimitRequests, LIMIT)
          .then((result) => {
            callback(null, result);
          })
          .catch((error) => {
            callback(error, null);
          });
      },
      create: (callback: { (error: unknown, result: unknown): void }) => {
        const createLimitRequests = createPromises.map((promise) => async () => await promise());
        async.parallelLimit(createLimitRequests, LIMIT)
          .then((result) => {
            callback(null, result);
          })
          .catch((error) => {
            callback(error, null);
          });
      },
      update: (callback: { (error: unknown, result: unknown): void }) => {
        const updateLimitRequests = updatePromises.map((promise) => async () => await promise());
        async.parallelLimit(updateLimitRequests, LIMIT)
          .then((result) => {
            callback(null, result);
          })
          .catch((error) => {
            callback(error, null);
          });
      },
    })
      .then(async (results) => {
        const responseResults = results as { create: IAchievementModal[]; update: IAchievementModal[]; delete: void[] };
        const order = values.map((value) => {
          if (value.isAdded || Number.isNaN(Number(value.id))) {
            const createdId = responseResults.create.find((c: IAchievementModal) => c.name === value.name)?.id;
            const updatedId = responseResults.update.find((u: IAchievementModal) => u.name === value.name)?.id;
            const newId = createdId ?? updatedId;
            if (!newId) {
              logger.error(`Failed to get id for achievement: ${value.name}`);
              return 0;
            }
            return Number(createdId);
          }
          return Number(value.id);
        });
        try {
          const isOrderChanged = initialValues.length !== order.length || !initialValues.every((a, i) => a.id === order[i]);
          if (isOrderChanged) {
            await updateOrder(order);
          }
          showToast({ textMessage: 'Achievements saved', variant: 'success' });
          onSubmit();
        } catch (error) {
          showToast({ textMessage: 'Failed to save order of achievements' });
          logger.error(error);
          onSubmit(false);
        }
      })
      .catch((error) => {
        showToast({ textMessage: 'Failed to save achievements' });
        logger.error(error);
        onSubmit(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleValidate = (values: IAchievementModal[]) => {
    let errors: { [key: string]: Partial<IAchievementModal> } = {};
    for (let i = 0; i < values.length; i++) {
      errors = validateGeneral(values, i, errors);
      errors = validateUniqueNames(values, i, errors);
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  );
}
