import { useField, useFormikContext } from 'formik';
import { flatten } from 'lodash-es';
import { useMemoryBankContext } from '../../../../../contexts/MemoryBankContext/MemoryBankContext';
import { AchievementCheckOperatorType } from '../../../../../dorian-shared/types/achievement/Achievement';
import { IAchievementModal } from '../../AchievementsModalTypes';
import { useAchievementsModalContext } from '../../context/AchievementsModalContext';
import { getGroupedOptions } from './AchievementWhenUtils';

export function useAchievementWhen(rowIndex: number) {
  const { memories } = useMemoryBankContext();
  const { episodes } = useAchievementsModalContext();
  const { values } = useFormikContext<IAchievementModal[]>();

  const [checkVariableIdField, checkVariableIdFieldMeta, checkVariableIdFieldHelpers] = useField<number>(`[${rowIndex}].check.variableId`);
  const [checkOperatorField, checkOperatorFieldMeta, checkOperatorFieldHelpers] = useField<AchievementCheckOperatorType>(`[${rowIndex}].check.operator`);
  const [checkValueField, checkValueFieldMeta, checkValueFieldHelpers] = useField<string | number>(`[${rowIndex}].check.value`);

  const [rewardContentIdField, rewardContentIdFieldMeta, rewardContentIdFieldHelpers] = useField<string>(`[${rowIndex}].reward.contentId`);
  const [rewardDelaySecField, rewardDelaySecFieldMeta, rewardDelaySecFieldHelpers] = useField<number>(`[${rowIndex}].reward.delaySec`);
  const [rewardTypeField, rewardTypeFieldMeta, rewardTypeFieldHelpers] = useField<number>(`[${rowIndex}].reward.type`);

  const availableWhenGroupedOptions = getGroupedOptions(memories, values, rowIndex, episodes);

  const searchValue = checkOperatorField.value === AchievementCheckOperatorType.HasCompleted
    ? checkValueField.value
    : checkVariableIdField.value;

  const value = flatten(availableWhenGroupedOptions.map((option) => option.options))
    .find((option) => String(option.value) === String(searchValue));

  return {
    value,
    availableWhenGroupedOptions,
    check: {
      checkVariableIdField,
      checkVariableIdFieldMeta,
      checkVariableIdFieldHelpers,
      checkOperatorField,
      checkOperatorFieldMeta,
      checkOperatorFieldHelpers,
      checkValueField,
      checkValueFieldMeta,
      checkValueFieldHelpers,
    },
    reward: {
      rewardContentIdField,
      rewardContentIdFieldMeta,
      rewardContentIdFieldHelpers,
      rewardDelaySecField,
      rewardDelaySecFieldMeta,
      rewardDelaySecFieldHelpers,
      rewardTypeField,
      rewardTypeFieldMeta,
      rewardTypeFieldHelpers,
    },
  };
}
